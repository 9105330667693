import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import MenuInformacoesConta from "../../components/MenuInformacoesConta/MenuInformacoesConta";
import routes from "./../../components/MainRoutes/constantes";

class MenuInformacoesContaContainer extends Component {
  state = { menuUsuarioOpen: false, anchorElMenuUsuario: {} };

  handleClickMenuUsuario = event => {
    this.setState({ menuUsuarioOpen: true, anchorElMenuUsuario: event.currentTarget });
  };

  handleRequestCloseMenuUsuario = () => {
    this.setState({ menuUsuarioOpen: false });
  };

  linkSuaConta = "/spotter/conta";
  linkCentralAjuda = "https://exactsal.es/CentralAjudaV2";
  linkPluginLinkedin = "https://exactsal.es/PluginLinkedIn-V2";
  linkLogin = routes.LOGIN.route;
  render() {
    if (!this.props.usuario.isRequesting) {
      return (
        <MenuInformacoesConta
          usuario={this.props.usuario}
          menuUsuarioOpen={this.state.menuUsuarioOpen}
          anchorElMenuUsuario={this.state.anchorElMenuUsuario}
          handleClickMenuUsuario={this.handleClickMenuUsuario}
          handleRequestCloseMenuUsuario={this.handleRequestCloseMenuUsuario}
          linkSuaConta={this.linkSuaConta}
          linkCentralAjuda={this.linkCentralAjuda}
          linkPluginLinkedin={this.linkPluginLinkedin}
          linkLogOff={this.linkLogin}
        />
      );
    }

    return null;
  }
}

MenuInformacoesContaContainer.propTypes = {
  usuario: PropTypes.object,
};

const mapStateToProps = state => ({
  usuario: state.usuario,
});

export default connect(mapStateToProps)(MenuInformacoesContaContainer);
