export const AppSettings = {
  UrlsParceirosPainelTelefonia: { nome: "urlsParceirosPainelTelefonia", chave: 1032 },
  ConfiguracaoImportacao: { nome: "configuracaoImportacao", chave: 1033 },
  UrlFunctionRDStation: { nome: "urlFunctionRDStation", chave: 1044 },
  EmailsSolicitacoesWPPExpansao: { nome: "umailsSolicitacoesWPPExpansao", chave: 1073 },
  UrlAutomacaoInteresseWppBusiness: { nome: "urlAutomacaoInteresseWppBusiness", chave: 1074 },
  GoogleClientIdAgendaIntegrada: { nome: "googleClientIdAgendaIntegrada", chave: 1117 },
  GoogleClientSecretAgendaIntegrada: { nome: "googleClientSecretAgendaIntegrada", chave: 1118 },
  TokensParceirosTelefonia: { nome: "tokensParceirosTelefonia", chave: 1119 },
  ServiceBusBATopicName: { nome: "serviceBusBATopicName", chave: 1120 },
  UrlExactDataSeeker: { nome: "urlExactDataSeeker", chave: 1121 },
  Office365ClientIdAgendaIntegrada: { nome: "office365ClientIdAgendaIntegrada", chave: 1122 },
  Office365ClientSecretAgendaIntegrada: { nome: "office365ClientSecretAgendaIntegrada", chave: 1123 },
  UrlTokenOffice365: { nome: "urlTokenOffice365", chave: 1124 },
  UrlSubscriptionOffice365: { nome: "urlSubscriptionOffice365", chave: 1125 },
  UrlOffice365Push: { nome: "urlOffice365Push", chave: 1126 },
  TempoPreVendedorOnline: { nome: "tempoPreVendedorOnline", chave: 1130 },
  EmpresasAgendamentoPorPv: { nome: "empresasAgendamentoPorPv", chave: 1133 },
  UrlExactIdentityMiddleware: { nome: "urlExactIdentityMiddleware", chave: 1134 },
  UrlVideoConferencia: { nome: "urlVideoConferencia", chave: 1135 },
  UrlExactNotificacao: { nome: "urlExactNotificacao", chave: 1136 },
  RolloutPermitirLogin: { nome: "rolloutPermitirLogin", chave: 1137 },
  PushNotificationWorkflow: { nome: "pushNotificationWorkflow", chave: 1143 },
  PushNotificationServerKey: { nome: "pushNotificationServerKey", chave: 1144 },
  UrlBaseFunctionRDCRM: { nome: "urlBaseFunctionRDCRM", chave: 1145 },
  UrlBillingStorage: { nome: "urlBillingStorage", chave: 1146 },
  PersonalizarRemetenteCadencia: { nome: "personalizarRemetenteCadencia", chave: 1147 },
  Office365RedirectUri: { nome: "office365RedirectUri", chave: 1148 },
  Office365CalendarsReadWrite: { nome: "office365CalendarsReadWrite", chave: 1149 },
  Office365UserRead: { nome: "office365UserRead", chave: 1150 },
  UtilizaServicoChunkProcessor: { nome: "utilizaServicoChunkProcessor", chave: 1151 },
  LimiteParaUsarChunkProcessor: { nome: "limiteParaUsarChunkProcessor", chave: 1152 },
  ChunkProcessorQueue: { nome: "chunkProcessorQueue", chave: 1153 },
  IpRange: { nome: "ipRange", chave: 1154 },
  UrlPluginLinkedin: { nome: "urlPluginLinkedin", chave: 1155 },
  SiteKeyGoogleCaptcha: { nome: "siteKeyGoogleCaptcha", chave: 1156 },
  ListaMetodosReadOnly: { nome: "listaMetodosReadOnly", chave: 1157 },
  HabilitarColetaResultadoLigacao: { nome: "habilitarColetaResultadoLigacao", chave: 1158 },
  UrlFinalizaLigacaoWebphone: { nome: "urlFinalizaLigacaoWebphone", chave: 1159 },
  NumTentativasExcecaoAPI: { nome: "numTentativasExcecaoAPI", chave: 1160 },
  RegexExcecaoURL: { nome: "regexExcecaoURL", chave: 1161 },
  UrlApiWare: { nome: "urlApiWare", chave: 1162 },
  EmailsAtivacaoAutomaticaUpsell: { nome: "emailsAtivacaoAutomaticaUpsell", chave: 1163 },
  ZohoClientId: { nome: "zohoClientId", chave: 1165 },
  ZohoClientSecret: { nome: "zohoClientSecret", chave: 1166 },
  ZohoRedirectUrl: { nome: "zohoRedirectUrl", chave: 1167 },
  TimeoutTimeline: { nome: "timeoutTimeline", chave: 1169 },
  UrlApidam: { nome: "urlApidam", chave: 1170 },
  ForcarBancoEscrita: { nome: "forcarBancoEscrita", chave: 1171 },
  HabilitaCaptchaGoogle: { nome: "habilitaCaptchaGoogle", chave: 1172 },
  SecretKeyGoogleCaptcha: { nome: "secretKeyGoogleCaptcha", chave: 1173 },
  TituloCustomizadoEmailAgendamento: { nome: "tituloCustomizadoEmailAgendamento", chave: 1174 },
  UrlIniciarLigacaoService: { nome: "urlIniciarLigacaoService", chave: 1176 },
  UtilizarServicoIniciarLigacao: { nome: "utilizarServicoIniciarLigacao", chave: 1177 },
  UrlUsageTracker: { nome: "urlUsageTracker", chave: 1180 },
  MultiplosFunis: { nome: "multiplosFunis", chave: 1182 },
  EmailsSolicitacaoTemplateHSM: { nome: "emailsSolicitacaoTemplateHSM", chave: 1183 },
  HubspotCRMClientId: { nome: "hubspotCRMClientId", chave: 1184 },
  HubspotCRMSecretId: { nome: "hubspotCRMSecretId", chave: 1185 },
  HubspotCRMRedirectUrl: { nome: "hubspotCRMRedirectUrl", chave: 1186 },
  HubspotCRMUrlAutenticacao: { nome: "hubspotCRMUrlAutenticacao", chave: 1188 },
  RolloutGuia: { nome: "rolloutGuia", chave: 1189 },
  RolloutPularEtapa: { nome: "rolloutPularEtapa", chave: 1195 },
  URLVideoConferenciaElos: { nome: "uRLVideoConferenciaElos", chave: 1196 },
  SecretVideoConferenciaElos: { nome: "secretVideoConferenciaElos", chave: 1197 },
  FormacoesBancoTalentos: { nome: "formacoesBancoTalentos", chave: 1204 },
  UrlFilaGenerica: { nome: "urlFilaGenerica", chave: 1205 },
  TempoSegundosExecutarWppEWf: { nome: "tempoSegundosExecutarWppEWf", chave: 1206 },
  EncerrarWFConclusaoAtividade: { nome: "encerrarWFConclusaoAtividade", chave: 1207 },
  UrlExactClub: { nome: "urlExactClub", chave: 1208 },
  SenhaExactClub: { nome: "senhaExactClub", chave: 1209 },
  OcultarCaptchaLogin: { nome: "ocultarCaptchaLogin", chave: 1211 },
  AdicionarProdutosVendidos: { nome: "adicionarProdutosVendidos", chave: 1212 },
  HabilitarMagicWrite: { nome: "habilitarMagicWrite", chave: 1213 },
  ValorPadraoCreditosMW: { nome: "valorPadraoCreditosMW", chave: 1215 },
  HabilitarSelectSimplesCargo: { nome: "habilitarSelectSimplesCargo", chave: 1216 },
  UrlMagicWrite: { nome: "urlMagicWrite", chave: 1217 },
  NovaUrlVideoconferencia: { nome: "novaUrlVideoconferencia", chave: 1218 },
  EmailExpansaoVideoconferencia: { nome: "emailExpansaoVideoconferencia", chave: 1219 },
  RolloutVideoconferencia: { nome: "rolloutVideoconferencia", chave: 1220 },
  RolloutRecuperarMultiplosFunis: { nome: "rolloutRecuperarMultiplosFunis", chave: 1221 },
  RolloutAlterarTipoReuniao: { nome: "rolloutAlterarTipoReuniao", chave: 1222 },
  RolloutAgendaInteligente: { nome: "rolloutAgendaInteligente", chave: 1223 },
  TokenNPS: { nome: "tokenNPS", chave: 1224 },
  MenuExactClub: { nome: "menuExactClub", chave: 1225 },
  RolloutMagicQualifier: { nome: "rolloutMagicQualifier", chave: 1226 },
  RolloutMenuConfiguravel: { nome: "rolloutMenuConfiguravel", chave: 1228 },
  RolloutRateLimit: { nome: "rolloutRateLimit", chave: 1229 },
  ConfiguracoesRateLimit: { nome: "configuracoesRateLimit", chave: 1230 },
  RolloutSegmentacao: { nome: "rolloutSegmentacao", chave: 1231 },
  RolloutEmailConvidados: { nome: "rolloutEmailConvidados", chave: 1232 },
  RolloutIntegracaoTallos: { nome: "rolloutIntegracaoTallos", chave: 1234 },
  UrlTranscrever: { nome: "urlTranscrever", chave: 1235 },
  UrlApiTallosV2: { nome: "urlApiTallosV2", chave: 1236 },
  BannerLogin: { nome: "bannerLogin", chave: 1237 },
  UrlLinkMensagemTallos: { nome: "urlLinkMensagemTallos", chave: 1238 },
  UrlApiTallosV3: { nome: "urlApiTallosV3", chave: 1239 },
  TempoMinimoParaTranscricao: { nome: "tempoMinimoParaTranscricao", chave: 1240 },
  MedirLatencia: { nome: "medirLatencia", chave: 1241 },
  TempoMedirLatencia: { nome: "tempoMedirLatencia", chave: 1242 },
  RolloutTimeline: { nome: "rolloutTimeline", chave: 1244 },
  MenuConfiguravel: { nome: "menuConfiguravel", chave: 1245 },
  RolloutIntegracaoAgendamentoAsync: { nome: "rolloutIntegracaoAgendamentoAsync", chave: 1246 },
  RolloutBuscadorValidador: { nome: "rolloutBuscadorValidador", chave: 1275 },
  RolloutResumoLeadMentorIA: { nome: "rolloutResumoLeadMentorIA", chave: 1289 },
};
