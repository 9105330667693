const AUTOMATIC_UPDATE_CHECK_INTERVAL = 1;
let updateCheckTimeoutId = null;

function checkUpdates(registration) {
  if (registration && registration.update) {
    registration.update();
    const timeoutId = setTimeout(() => {
      checkUpdates(registration);
    }, 1000 * 60 * AUTOMATIC_UPDATE_CHECK_INTERVAL);

    updateCheckTimeoutId = timeoutId;
  }
}

export function clearUpdateCheckTimeout() {
  if (updateCheckTimeoutId) {
    clearTimeout(updateCheckTimeoutId);
    updateCheckTimeoutId = null;
  }
}

/* ========================================================================
Additional event management for service worker registration

Note: the possible service worker states are:
-----------------
"installing" - the install event has fired, but not yet complete
"installed"  - install complete
"activating" - the activate event has fired, but not yet complete
"activated"  - fully active
"redundant"  - discarded. Either failed install, or it's been replaced by a newer version
 ========================================================================== */
export function serviceWorkerRegistrationEnhancements(config, registration) {
  checkUpdates(registration);

  registration.addEventListener("updatefound", () => {
    // this is assumed to exists at the point in time
    // when the `updatefound` event fires.
    const newWorker = registration.installing;

    // add a `statechange` listener to the new service worker
    // object. We want to catch a possible state change to
    // `activated`, and if we catch this then we'll look for
    // and execute the `onActivated` event handler.
    newWorker.addEventListener("statechange", () => {
      if (newWorker.state === "activated" && config && config.onActivated) {
        config.onActivated(registration);
      }
    });
  });
}
