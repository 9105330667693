import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Subheader from "../../components/Subheader";

import { URL_BASE_SPOTTER } from "../../utils/constants/variaveisDeAmbiente";

const SubheaderContainer = props => {
  const history = useHistory();

  const handleBotaoVoltar = () => {
    const url = document.referrer;

    if (url.includes(props.urlBaseSpotter)) {
      history.goBack();
    } else {
      history.push("/spotter/base-leads/funil");
    }
  };

  return <Subheader {...props} handleBotaoVoltar={handleBotaoVoltar} />;
};

SubheaderContainer.propTypes = {
  /** Titulo exibido no subheader */
  titulo: PropTypes.string.isRequired,
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      /** Título do breadcrumb */
      titulo: PropTypes.string.isRequired,
      /** Url do breadcrumb */
      url: PropTypes.string,
      /** Indica se a url é externa (`httm://google.com`) ou interna (url do front do react) */
      urlExterna: PropTypes.bool,
    })
  ),
  /** Conteúdo a ser rendereizado. */
  children: PropTypes.node,
  /** @ignore */
  linkVersaoClassica: PropTypes.string,
  /** Define o tipo do título do subheader como editável */
  isTituloEditavel: PropTypes.bool,
  /** Texto exibido no input quando vazio */
  placeholderTitulo: PropTypes.string,
  /** @ignore */
  urlBaseSpotter: PropTypes.string,
  /** Função que altera o estado/valor do componente título editável */
  onChangeTituloEditavel: PropTypes.func,
  /** Define o estilo do título editável como erro (cor vermelha) */
  hasErrorTituloEditavel: PropTypes.bool,
};

const mapStateToProps = state => ({
  urlBaseSpotter: state.variaveisAmbiente.listaVariaveis[URL_BASE_SPOTTER],
});

export default connect(mapStateToProps)(SubheaderContainer);
