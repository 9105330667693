import React from "react";

import CampoBuscarLeads from "../../components/CampoBuscarLeads/CampoBuscarLeads";

const handleOnKeypressBuscarLead = event => {
  if (event.key === "Enter" && event.currentTarget.value.length > 0) {
    window.location = `/spotter/resultado-busca-geral?texto=${encodeURIComponent(event.currentTarget.value)}`;
  }
};

const CampoBuscarLeadsContainer = () => <CampoBuscarLeads handleOnKeypressBuscarLead={handleOnKeypressBuscarLead} />;
export default CampoBuscarLeadsContainer;
