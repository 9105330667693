import React from "react";
import { connect } from "react-redux";
import { IntlProvider, useIntl } from "react-intl";
import moment from "moment";
import PropTypes from "prop-types";
import { setLocale as setLocaleAction } from "../../../store/reducers/locale/localeActions";
import env from "../../src/../../environment";

let intl;

const IntlGlobalProvider = ({ children }) => {
  intl = useIntl();
  return children;
};

const ConnectedIntlProvider = ({ children, isRequesting, setLocale, ...props }) => {
  // para auxiliar no debug
  React.useEffect(() => {
    if (env.NODE_ENV === "development") {
      window.debugReactIntl = {
        pt: () => setLocale("pt-BR"),
        en: () => setLocale("en-US"),
        es: () => setLocale("es-ES"),
      };
    }
  }, []);

  React.useEffect(
    () => {
      if (props.locale) {
        moment.locale(props.locale);
      }
    },
    [props.locale]
  );

  if (isRequesting === false) {
    return (
      <IntlProvider
        defaultLocale="pt-BR"
        {...props}
        key={props.locale}
        wrapRichTextChunksInFragment
        defaultRichTextElements={{
          b: (...chunks) => <b>{chunks}</b>,
        }}
      >
        <IntlGlobalProvider>{children}</IntlGlobalProvider>
      </IntlProvider>
    );
  }

  return null;
};

ConnectedIntlProvider.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.node,
  isRequesting: PropTypes.bool,
  setLocale: PropTypes.func,
};

const mapStateToProps = state => ({
  locale: state.locale.language,
  messages: state.locale.messages,
  isRequesting: state.locale.isRequesting,
});

const mapDispatchToProps = { setLocale: setLocaleAction };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedIntlProvider);

export function appIntl() {
  return intl;
}
