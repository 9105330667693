import React from "react";
import { RichUtils } from "draft-js";
import PropTypes from "prop-types";

import { blockRendererFn, defaultEditorStyles, styleMap } from "../../utils/editorTexto/editorTexto";
import { convertStyleStringToObject } from "../../utils/string/string";
import EditorTexto from "../../components/EditorTexto";
import ConnectedIntlProvider from "../ConnectedIntlProvider";

class EditorTextoContainer extends React.Component {
  focus = () => this.editor.focus();

  handleAtalhosTeclado = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.props.onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  handleOnBlur = (event, focus) => {
    if (event.relatedTarget && event.relatedTarget.dataset.autoFocusEditor === "true") {
      setTimeout(focus, 0);
    }

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  customStyleFn = style => {
    const styleNames = style.toJS();

    return styleNames.reduce((styles, styleName) => {
      if (Object.keys(styleMap).find(itemStyle => itemStyle === styleName)) {
        return {};
      }

      let newStyles = { ...styles };
      if (!defaultEditorStyles.includes(styleName)) {
        newStyles = convertStyleStringToObject(styleName, styles);
      }
      return newStyles;
    }, {});
  };

  render = () => (
    <ConnectedIntlProvider>
      <EditorTexto
        handleAtalhosTeclado={this.handleAtalhosTeclado}
        mediaBlockRenderer={blockRendererFn}
        customStyleFn={this.customStyleFn}
        handleOnBlur={this.handleOnBlur}
        {...this.props}
      />
    </ConnectedIntlProvider>
  );
}

EditorTextoContainer.propTypes = {
  editorState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

export default EditorTextoContainer;
