import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
import Axios from "axios";

import App from "../../components/App/App";
import { setLocale as setLocaleAction, configureMoment } from "../../../store/reducers/locale/localeActions";
import localStorageManager from "../../utils/storage";
import { ADICIONAR_SERVICE_WORKER, JWT_TOKEN } from "../../utils/storage/constantesStorage";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import env from "../../../environment";
import { requestInterceptor, requestInterceptorError } from "../../utils/api/interceptors";

export const isServiceWorkerHabilitado = async () => {
  const baseUrl = env.REACT_APP_BASE_URL;
  Axios.interceptors.request.use(requestInterceptor, requestInterceptorError);
  const response = await Axios.get(`${baseUrl}/api/pipeline/appsetting/ServiceWorkerHabilitado`);
  return response.data;
};
class AppContainer extends React.PureComponent {
  componentDidMount = async () => {
    const estaLogado = localStorageManager.getItem(JWT_TOKEN) != null;
    if (!estaLogado) {
      await this.props.setLocale("pt-BR");
      configureMoment("pt-BR");
    }

    if (estaLogado) {
      isServiceWorkerHabilitado().then(resp => {
        const estaNoRollout = resp;
        localStorageManager.setItem(ADICIONAR_SERVICE_WORKER, estaNoRollout);
        if (estaNoRollout && env.NODE_ENV === "production") {
          if (document.readyState === "complete") {
            serviceWorkerRegistration.register();
          } else {
            window.addEventListener("load", () => {
              serviceWorkerRegistration.register();
            });
          }
        } else {
          serviceWorkerRegistration.unregister();
        }
      });
    }
  };

  render = () => <App locale={this.props.locale} />;
}

AppContainer.propTypes = {
  locale: PropTypes.object,
  setLocale: PropTypes.func,
};

const mapStateToProps = state => ({
  locale: state.locale,
});

const mapDispatchToProps = dispatch => ({
  setLocale: locale => dispatch(setLocaleAction(locale)),
});

const enhancer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhancer(AppContainer);
