import React from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navbar from "../../components/Navbar/Navbar";

const NavbarContainer = ({ usuarioIsRequesting }) => (usuarioIsRequesting ? null : <Navbar />);

NavbarContainer.propTypes = {
  usuarioIsRequesting: PropTypes.bool,
};

const mapStateToProps = state => ({
  usuarioIsRequesting: state.usuario.isRequesting,
});

export default connect(mapStateToProps)(NavbarContainer);
