import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { buscarLogado as buscarLogadoAction } from "../../../store/reducers/usuario/usuarioActions";

const mapStateToProps = state => ({
  usuario: state.usuario,
});

const mapDispatchToProps = dispatch => ({
  buscarLogado: () => dispatch(buscarLogadoAction()),
});

function getDisplayName(WrappedComponent) {
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || "Component";
  return `withUsuario(${wrappedComponentName})`;
}

function withUsuario(WrappedComponent) {
  const withUsuarioComponent = ({ usuario, buscarLogado, ...rest }) => {
    if (!usuario) {
      buscarLogado();
      return <div />;
    }
    return <WrappedComponent usuario={usuario} {...rest} />;
  };

  withUsuarioComponent.propTypes = {
    usuario: PropTypes.object,
    buscarLogado: PropTypes.func.isRequired,
  };

  withUsuarioComponent.defaultProps = {
    usuario: null,
  };

  withUsuarioComponent.displayName = getDisplayName(WrappedComponent);

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(withUsuarioComponent);
}

export default withUsuario;
